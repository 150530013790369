import Image from 'components/common/Image';
import { Image as ImageInterface } from 'interfaces/cms/common';
import styles from './ResponsiveImage.module.scss';
import Head from 'next/head';

export enum ArtDirection {
  RESPONSIVE = 'responsive',
  CROP = 'crop_center',
  USE_MOBILE = 'use_mobile_image',
}

export interface ResponsiveImageProps {
  src: ImageInterface;
  srcMobile: ImageInterface | null;
  artDirection: string;
  width?: string | number;
  height?: string | number;
  priority?: boolean;
}

function ResponsiveImage({
  src,
  srcMobile,
  artDirection = 'responsive',
  priority = false,
}: Readonly<ResponsiveImageProps>) {
  const getArtDirection = (art_direction: string): ArtDirection => {
    switch (art_direction) {
      case 'crop_center':
        return ArtDirection.CROP;
      case 'use_mobile_image':
        return ArtDirection.USE_MOBILE;
      default:
        return ArtDirection.RESPONSIVE;
    }
  };

  const getAlt = (source: ImageInterface): string =>
    source.alt || source.name || 'image';

  if (getArtDirection(artDirection) === ArtDirection.CROP) {
    return (
      <>
        <Head>
          {priority && (
            <>
              {srcMobile && (
                <link
                  rel="preload"
                  href={srcMobile.url}
                  as="image"
                  media="(max-width: 768px)"
                />
              )}
              {src && (
                <link
                  rel="preload"
                  href={src.url}
                  as="image"
                  media="(min-width: 769px)"
                />
              )}
            </>
          )}
        </Head>
        <div className={styles.desktopImage}>
          <Image
            src={src.url}
            alt={getAlt(src)}
            sizes="100vw"
            width={src.width}
            height={src.height}
            priority={priority}
          />
        </div>
        <div
          className={`${styles.mobileImage} ${styles.crop_center}`}
          style={{
            width: src.width,
            height: src.height,
            position: 'relative',
          }}
        >
          <Image src={src.url} alt={getAlt(src)} fill priority={priority} />
        </div>
      </>
    );
  }

  if (getArtDirection(artDirection) === ArtDirection.USE_MOBILE) {
    return (
      <>
        <Image
          src={srcMobile ? srcMobile.url : src.url}
          alt={srcMobile ? getAlt(srcMobile) : getAlt(src)}
          width={srcMobile ? srcMobile.width : src.width}
          height={srcMobile ? srcMobile.height : src.height}
          priority={priority}
          className="md:hidden"
        />

        <Image
          src={src.url}
          alt={getAlt(src)}
          width={src.width}
          height={src.height}
          priority={priority}
          className="hidden md:block"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </>
    );
  }

  return (
    <Image
      src={src.url}
      alt={getAlt(src)}
      sizes="100vw"
      width={src.width}
      height={src.height}
      priority={priority}
      style={{
        width: '100%',
        height: 'auto',
      }}
    />
  );
}

export default ResponsiveImage;
